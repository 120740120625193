import {Modal, Card, Avatar, Dropdown} from "antd"
import type {MenuProps} from 'antd';
import {JSSTheme} from "@/lib/Types"
import {DeleteOutlined, MoreOutlined} from "@ant-design/icons"
import {removeApp} from "@/lib/services/api"
import {useState} from "react"
import Link from "next/link"
import {renameVariablesCapitalizeAll} from "@/lib/helpers/utils"
import {createUseStyles} from "react-jss"
import {getGradientFromStr} from "@/lib/helpers/colors"
import {ListAppsItem} from "@/lib/Types"
import {useAppsData} from "@/contexts/app.context"
import { useRouter } from "next/router"

const useStyles = createUseStyles((theme: JSSTheme) => ({
    cardWrapper: {
        height: "auto",
    },
    card: {
        width: 400,
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        "&:hover": {
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            borderColor: theme.colorPrimary,
        },
        "& .ant-card-meta": {
            height: "110%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "& .ant-card-meta-title div": {
            textAlign: "center",
        },

        '& .ant-card-head': {
            padding: 16,
            height: 60,
        },

        '& .ant-card-head-title': {
            color: "#000",
            display: "flex",
            alignItems: "center",
            gap: 8,
        },
        '& .ant-card-body': {
            padding: 16,
            color: "#64748b",
            flex: 1,
            display: "flex",
            flexDirection: "column",
        },
    },
    cardDescription: {
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        fontSize: ".875rem",
        lineHeight: 1.5,
        flex: 1,
    },
    cardCover: {
        "z-index": 1,
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        background: "transparent",
        margin: "auto",
        width: "300px",
        height: "70px",
        display: "flex",
        overflow: "hidden",
        "flex-direction": "column",
        "justify-content": "space-between",
    },
    cardLink: {
        display: "block",
        height: "100%",
    },
    cardTags: {
        display: "flex",
        gap: 8,
        marginTop: 8,
    },
    cardTag: {
        padding: "0.4rem 0.5rem",
        background: "rgb(248 250 252)",
        color: "#0f172a",
        fontSize: ".75rem",
        fontWeight: 600,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "rgb(100 116 139)",
        borderRadius: "calc(0.5rem - 2px)",
    },
}))

const DeleteModal: React.FC<{
    open: boolean
    handleOk: () => Promise<void>
    handleCancel: () => void
    appName: string
    confirmLoading: boolean
}> = ({open, handleOk, handleCancel, appName, confirmLoading}) => {
    return (
        <Modal
            title="Are you sure?"
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading} // add this line
            onCancel={handleCancel}
            okText="Yes"
            cancelText="No"
        >
            <p>Are you sure you want to delete {appName}?</p>
        </Modal>
    )
}

const AppCard: React.FC<{
    app: ListAppsItem
}> = ({app}) => {
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const {mutate} = useAppsData()
    const router = useRouter()

    const showDeleteModal = () => {
        setVisibleDelete(true)
    }

    const handleDeleteOk = async () => {
        setConfirmLoading(true)
        try {
            await removeApp(app.app_id)
            mutate()
        } catch (error) {
            console.error(error)
        } finally {
            setVisibleDelete(false)
            setConfirmLoading(false)
        }
    }
    const handleDeleteCancel = () => {
        setVisibleDelete(false)
    }

    const classes = useStyles()

    const items: MenuProps['items'] = [
        {
          label: 'Delete',
          icon: <DeleteOutlined />,
          key: '0',
          onClick: showDeleteModal,
        },
    ];

    return (
        <>
            <div className={classes.cardWrapper}>
                <Link data-cy="app-card-link" href={`/apps/${app.app_id}/playground`}>
                    <Card
                        className={classes.card}
                        extra={(
                            <div onClick={(e) => e.preventDefault()}>
                                <Dropdown menu={{ items }} trigger={['click']}>
                                    <MoreOutlined style={{ fontSize: 20 }} />
                                </Dropdown>
                            </div>
                        )}
                        title={(
                            <>
                                <Avatar
                                    size="default"
                                    style={{backgroundColor: "hsl(150, 52%, 62%)"}} // Example: blue in HSL
                                >
                                    {app.app_name.charAt(0).toUpperCase()}
                                </Avatar>
                                <div>
                                    {renameVariablesCapitalizeAll(app.app_name)}
                                </div>
                            </>
                        )}
                    >
                        <div className={classes.cardDescription}>
                            The General Q&A agent handles general user inquiries and provides informed answers based on knowledge from the source library.
                        </div>
                        <div className={classes.cardTags}>
                            <div className={classes.cardTag}>General</div>
                            <div className={classes.cardTag}>Q&A</div>
                            <div className={classes.cardTag}>Agent</div>
                            <div className={classes.cardTag}>Initial</div>
                        </div>
                    </Card>
                </Link>
            </div>

            <DeleteModal
                open={visibleDelete}
                handleOk={handleDeleteOk}
                handleCancel={handleDeleteCancel}
                appName={app.app_name}
                confirmLoading={confirmLoading}
            />
        </>
    )
}

export default AppCard
